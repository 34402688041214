import * as types from '../constants/actionTypes'

const initialState = {
  isLoading: false,
  upcomingData: []
}

const Upcoming = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING_DATA:
      const result = { ...state, isLoading: action.isLoading }
      return result
    case types.GET_UPCOMING_CEREMONY_DATA:
      return {...state, upcomingData: action.payload}
    default:
      return state
  }
}

export default Upcoming