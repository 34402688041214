import React from 'react'
import { Route, Switch } from 'react-router-dom';

const FuneralHouse = React.lazy(() => import("../container/pages/FuneralHouse"))
const PlanningScreen = React.lazy(() => import('../container/pages/PlanningScreen'))
const CeremonyRoom = React.lazy(() => import('../container/pages/CeremonyRoom'))
const WaitingRoom = React.lazy(() => import('../container/pages/WaitingRoom'))
const ReservationList = React.lazy(() => import("../container/pages/ReservationList"))

export const routes =
  <Switch>
    <Route exact={true} path='/' component={FuneralHouse} />
    <Route exact={true} path='/planning/:crematoriumId/:lg' component={PlanningScreen} />
    <Route exact={true} path='/ceremony/:rsid/:crematoriumId/:lg' component={CeremonyRoom} />
    <Route exact={true} path='/waiting/:rsid/:crematoriumId/:lg' component={WaitingRoom} />
    <Route exact={true} path='/ReservationList/:rsid/:crematoriumId/:lg' component={ReservationList} />
  </Switch>