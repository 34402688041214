import * as types from '../constants/actionTypes'

const initialState = {
  isLoading: false,
  funeralData: {}
}

const Funeral = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING_DATA:
      const result = { ...state, isLoading: action.isLoading }
      return result
    case types.GET_FUNERAL_DATA:
      return { ...state, funeralData: action.payload }
    default:
      return state
  }
}

export default Funeral