import * as types from '../constants/actionTypes'

const initialState = {
  isLoading: false,
  roomData: {},
}

const CeremonyRoom = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING_DATA:
      const result = { ...state, isLoading: action.isLoading }
      return result
    case types.GET_CEREMONY_ROOM_DATA:
      return { ...state, roomData: action.payload }
    case types.GET_WAITING_ROOM_DATA:
        return { ...state, roomData: action.payload }
    default:
      return state
  }
}

export default CeremonyRoom